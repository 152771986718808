import { hasValue, isNotNullOrUndefined } from "@xxl/common-utils";
import { Cart, DeliveryTruck } from "@xxl/icons";
import { log } from "@xxl/logging-utils";
import React, { useState } from "react";
import { useQuickshop } from "react-app/src/hooks/useQuickshop/useQuickshop";
import stringFormat from "string-format";
import { useSharedData } from "../../contexts/SharedData";
import { useTracking } from "../../contexts/Tracking";
import { useTranslations } from "../../contexts/Translations/TranslationsContext";
import { StyledCheckbox } from "../../styled";
import { showSalesPriceDisclaimer } from "../../utils/PriceDisplay/price-display";
import { getFileSpinUrl } from "../../utils/getFilespinUrl";
import { FavoriteProductHeart } from "../Favorites/FavoriteProductHeart";
import { SharedOverlay } from "../Favorites/Favorites.styled";
import {
  SET_SHARED_LIST,
  useFavoritesContext,
} from "../Favorites/FavoritesState";
import { saveCurrentScrollPosition } from "../Search/SearchScrollHelper";
import { NewRibbon } from "./NewRibbon/NewRibbon";
import {
  CampaignIcon,
  CardButtonIcons,
  ColorsAndRatingContainer,
  DeliveryInfo,
  DeliveryTime,
  HighlightedLabel,
  ImageContainer,
  PriceSplash,
  ProductCardContainer,
  ProductInfoContainer,
  ProductLink,
  ProductVariantImage,
  ProductVariantThumb,
  ProductVariants,
  ProductVariantsSkeleton,
  QuickShopButton,
  StockStatus,
  StockStatusBullet,
  StockStatuses,
} from "./Product.styled";
import { ProductBasicInfo } from "./ProductBasicInfo";
import { ProductClasifications } from "./ProductClasifications";
import { MobileImageCarouselOrSingleProductImage } from "./ProductImage/MobileImageCarouselOrSingleProductImage";
import { ProductRibbons } from "./ProductRibbons";
import { Rating } from "./Rating/Rating";
import {
  DEFAULT_GRID_VALUE_DESKTOP,
  PRODUCT_VARIANT_THUMB_SIZE,
} from "./constants";
import {
  getAvailableColorsText,
  getOptionalRelativeHref,
  getStockStatusLabel,
  trackProductClick,
} from "./product-helper";
import type { ProductProps } from "./types";

const Product = ({
  AddToCartRow,
  PriceComponent,
  additionalSales,
  as = "div",
  colorTheme,
  hidden = false,
  highlightedLabel,
  iconUrl,
  isHoverable = false,
  isInCarousel,
  isSliderProductList = false,
  personalizedProductListName,
  positionInList,
  priceSplash,
  prioritizeImageLoad,
  product,
  productMetaData,
  quickshopEnabled = true,
  selectedColumnsNumber = DEFAULT_GRID_VALUE_DESKTOP,
  selectedFilters,
  showHighlightedLabel = false,
}: ProductProps) => {
  const { t } = useTranslations();
  const { state: favoritesState, dispatch: favoritesDispatch } =
    useFavoritesContext();
  const { siteUid, featureToggles } = useSharedData().data;
  const { toggle_delivery_widget } = featureToggles;
  const {
    averageRating,
    badges,
    brandName,
    deliveryOptions,
    multiChannelAvailability,
    name,
    primaryImage,
    productImages,
    selectedStoreStockStatus,
    stockStatus,
    styleOptions,
    ticket,
    type,
    usps = [],
  } = product;
  const productCode = "code" in product ? product.code : "";
  const [isFocused, setIsFocused] = useState(false);
  const handleMouseEnter = () => setIsFocused(true);
  const handleMouseLeave = () => setIsFocused(false);
  const { showQuickshop: _showQuickshop, openQuickshop } = useQuickshop();
  const showQuickshop = _showQuickshop && quickshopEnabled;
  const trackers = useTracking();
  const showAddToCartRow = AddToCartRow !== undefined;
  const availableColors = styleOptions !== undefined ? styleOptions.length : 0;
  const isBundle = type === "BUNDLE";
  const shouldShowStoreStock =
    selectedStoreStockStatus !== undefined && !isBundle;
  const deliveryLabel =
    deliveryOptions?.expressDeliveryAvailable === true
      ? t("product.express.delivery")
      : deliveryOptions?.deliveryEarliest !== undefined &&
          deliveryOptions.deliveryLatest !== undefined
        ? stringFormat(
            t("product.day.delivery"),
            deliveryOptions.deliveryEarliest.toString(),
            deliveryOptions.deliveryLatest.toString()
          )
        : null;
  const showDeliveryTag = toggle_delivery_widget && deliveryLabel !== null;
  const hasUsp = hasValue(usps);

  const handleProductClickTracking = (): void => {
    if (productMetaData === undefined) {
      return;
    }
    trackProductClick(
      trackers,
      product,
      productMetaData,
      personalizedProductListName,
      ticket
    );
  };

  const handleClick = (): void => {
    saveCurrentScrollPosition(document.documentElement.scrollTop);
    handleProductClickTracking();
  };

  const handleQuickShopClick = (event: React.MouseEvent) => {
    if (!hasValue(productCode)) {
      log.error("Product is missing product code.", product);
      return;
    }

    openQuickshop({
      additionalSales,
      event,
      productCode,
      ticket,
    });
    handleProductClickTracking();
  };

  return (
    <ProductCardContainer
      data-testid="list-product"
      hidden={hidden}
      columnAmount={selectedColumnsNumber}
      onMouseEnter={isHoverable ? handleMouseEnter : undefined}
      onTouchStart={isHoverable ? handleMouseEnter : undefined}
      onMouseLeave={isHoverable ? handleMouseLeave : undefined}
      onTouchEnd={isHoverable ? handleMouseLeave : undefined}
      isFocused={isFocused}
      isHoverable={isHoverable}
      showHighlightedLabel={showHighlightedLabel}
      showStoreStock={shouldShowStoreStock}
      showSalesPriceDisclaimer={showSalesPriceDisclaimer(siteUid)}
      showDeliveryTag={showDeliveryTag}
      role="listitem"
      isClickable={!favoritesState.isInShareMode}
      as={as}
      showAddToCartRow={showAddToCartRow}
    >
      {favoritesState.isInShareMode && (
        <SharedOverlay>
          <StyledCheckbox
            label={t("favorites.share.checkbox.label")}
            handleChange={(event, checked) => {
              if (isNotNullOrUndefined(event.target.value) && checked) {
                favoritesDispatch({
                  type: SET_SHARED_LIST,
                  payload: event.target.value,
                });
              }
            }}
            value={product.code}
            hideLabel={true}
            isBigSize={true}
          />
        </SharedOverlay>
      )}
      <ProductLink
        href={getOptionalRelativeHref({
          product,
          personalizedProductListName,
          selectedFilters,
        })}
        onClick={handleClick}
        data-id={productCode}
        data-testid={productCode}
        hasFixedHeight={!showAddToCartRow}
      >
        <ImageContainer
          columnAmount={selectedColumnsNumber}
          isFocused={isFocused}
          isInCarousel={isInCarousel}
        >
          <MobileImageCarouselOrSingleProductImage
            columnAmount={selectedColumnsNumber}
            imageAltText={product.name}
            isFocused={isFocused}
            listOfProductUrls={productImages ?? []}
            primaryImageUrl={primaryImage}
            showOnlyOneImage={isSliderProductList}
            prioritizeImageLoad={prioritizeImageLoad}
            positionInList={positionInList}
          />
          {priceSplash !== undefined && (
            <PriceSplash>
              <NewRibbon
                colorTheme={colorTheme}
                label={priceSplash}
                direction="left"
              />
            </PriceSplash>
          )}
          {iconUrl !== undefined && iconUrl !== "" && !isFocused && (
            <CampaignIcon columnAmount={selectedColumnsNumber}>
              <img src={iconUrl} alt="icon" />
            </CampaignIcon>
          )}
          {isHoverable && isFocused ? (
            <ProductClasifications
              limit={3}
              selectedColumnsNumber={selectedColumnsNumber}
              usps={usps}
            />
          ) : (
            <ProductRibbons
              badges={badges ?? []}
              multiChannelAvailability={multiChannelAvailability ?? []}
              isBundle={isBundle}
            />
          )}
          <CardButtonIcons hasUsp={hasUsp} isFocused={isHoverable && isFocused}>
            <FavoriteProductHeart productStyle={product.code ?? ""} />
            {showQuickshop && (
              <QuickShopButton
                onClick={handleQuickShopClick}
                aria-label={t("product.details.add.to.cart.label")}
                title={t("product.details.add.to.cart.label")}
                data-testid="quickshop-button"
                data-id={productCode}
                className="quickshop-button"
              >
                <Cart />
              </QuickShopButton>
            )}
          </CardButtonIcons>
        </ImageContainer>
        <ProductInfoContainer className="product-info-container">
          <ProductBasicInfo
            columnAmount={selectedColumnsNumber}
            brand={brandName}
            name={name}
          />
          {PriceComponent}
          {showHighlightedLabel ? (
            <HighlightedLabel
              data-testid="highlighted-label"
              colorThemeName={colorTheme.name}
              isTransparent={highlightedLabel === undefined}
            >
              {highlightedLabel}
            </HighlightedLabel>
          ) : null}
          <ColorsAndRatingContainer
            hasColorsAndRating={
              averageRating !== undefined && availableColors > 0
            }
          >
            {availableColors > 0 ? (
              <span>
                {getAvailableColorsText({
                  numberOfAvailableColors: availableColors,
                  t,
                })}
              </span>
            ) : null}
            {averageRating !== undefined && (
              <Rating averageRating={averageRating} />
            )}
          </ColorsAndRatingContainer>
          <ProductVariantsSkeleton isFocused={isHoverable && isFocused} />
          <StockStatuses columnAmount={selectedColumnsNumber}>
            {stockStatus !== undefined ? (
              <StockStatus>
                <StockStatusBullet level={stockStatus} />
                <span>
                  {`${getStockStatusLabel(stockStatus, t)} ${t(
                    "stock.online"
                  )}`}
                </span>
              </StockStatus>
            ) : null}
            {shouldShowStoreStock ? (
              <StockStatus>
                <StockStatusBullet level={selectedStoreStockStatus} />
                <span>
                  {`${getStockStatusLabel(selectedStoreStockStatus, t)} ${t(
                    "stock.store"
                  )}`}
                </span>
              </StockStatus>
            ) : null}
          </StockStatuses>
          {showDeliveryTag ? (
            <DeliveryInfo>
              <DeliveryTruck fontSize={18} />
              <DeliveryTime data-testid="delivery-label">
                {deliveryLabel}
              </DeliveryTime>
            </DeliveryInfo>
          ) : null}
        </ProductInfoContainer>
      </ProductLink>
      {AddToCartRow !== undefined && AddToCartRow}

      {isNotNullOrUndefined(styleOptions) && styleOptions.length > 1 ? (
        <ProductVariants
          columnAmount={selectedColumnsNumber}
          showHighlightedLabel={showHighlightedLabel}
          showSalesPriceDisclaimer={showSalesPriceDisclaimer(siteUid)}
          isFocused={isFocused}
        >
          {styleOptions.map((style, index) => {
            const primaryImageURL =
              "primaryImageURL" in style ? style.primaryImageURL : undefined;
            const productUrl = style.url;

            if (
              !isNotNullOrUndefined(primaryImageURL) ||
              !isNotNullOrUndefined(productUrl)
            ) {
              return null;
            }

            return (
              <ProductVariantThumb href={productUrl} key={index}>
                <ProductVariantImage
                  data-private={true}
                  src={getFileSpinUrl({
                    baseUrl: primaryImageURL,
                    width: PRODUCT_VARIANT_THUMB_SIZE,
                    height: PRODUCT_VARIANT_THUMB_SIZE,
                  })}
                  alt={t("product.image")}
                  title={t("product.image")}
                  fetchpriority="low"
                />
              </ProductVariantThumb>
            );
          })}
        </ProductVariants>
      ) : null}
    </ProductCardContainer>
  );
};

export { Product };
