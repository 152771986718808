import { useMediaQuery } from "@mui/material";
import { isNotEmpty } from "@xxl/common-utils";
import { dispatchSetQuickShopProductCode } from "react-app/src/utils/xxl-event";
import { laptopMediaQuery } from "react-app/src/utils/xxl-screen";
import { useSharedData } from "../../contexts/SharedData";
import type { AdditionalSales } from "../../utils/ProductData/product-card-data-helper";

export const useQuickshop = () => {
  const { featureToggles } = useSharedData().data;
  const { toggle_quick_shop } = featureToggles;
  const isLaptopSize = useMediaQuery(laptopMediaQuery);
  const isNextPage =
    typeof window === "undefined" || typeof window._sharedData === "undefined";
  const showQuickshop = isLaptopSize && toggle_quick_shop && isNextPage;

  const openQuickshop = ({
    additionalSales,
    event,
    productCode,
    ticket,
  }: {
    event: React.MouseEvent;
    productCode: string;
    additionalSales?: AdditionalSales;
    ticket?: string;
  }) => {
    event.preventDefault();
    event.stopPropagation();

    if (isNotEmpty(productCode)) {
      dispatchSetQuickShopProductCode({ productCode, ticket, additionalSales });
    }
  };

  return { showQuickshop, openQuickshop };
};
